*, *::before, *::after { box-sizing: border-box; }

html {
  box-sizing: border-box;
}

body {
  margin: 0;
  scroll-behavior: smooth;
  color: $color-secondary;

  // there is no font-size here because we respect the browser default
  font-family: $font-family-sans-serif;
  font-weight: normal;
  line-height: 1;
  font-kerning: normal;
  text-rendering: optimizeSpeed;
}

.ion-content {
  --padding-bottom: #{rem-calc($spacing-s)};
  display: flex;
  flex-direction: column;
}

.ion-content--has-bg {
  --background: none;
  background: #{$color-white} url($image-path + "tacticks.svg") no-repeat bottom -90px left / contain;

  #background-content {
    background: transparent !important;
  }
}

.ion-page-invisible {
  opacity: 1 !important;
}

// @todo refactoren
@mixin settingsPanelShadow {
  -webkit-box-shadow: 0 2px 32px 0 rgba(130,130,130,0.28);
  -moz-box-shadow: 0 2px 32px 0 rgba(130,130,130,0.28);
  box-shadow: 0 2px 32px 0 rgba(130,130,130,0.28);
}

.settings-panel {
  @include settingsPanelShadow;
  margin: 15px auto;
  padding: 15px;
  width: calc(100% - 30px);
}