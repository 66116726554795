// Foundation Colors
$color-black: #000000;
$color-white: #FFFFFF;

// Primary Colors
$color-primary: #4CBF8C; //var(--ion-color-primary);
$color-primary-dark: #3FB27F; //var(--ion-color-primary-contrast);

$color-secondary: #221C46; //var(--ion-color-secondary)
$color-secondary-dark: #150F39; //var(--ion-color-secondary-contrast)
$color-secondary-light: rgba($color-secondary, .5); //var(--ion-color-secondary-tint)
$color-secondary-lighter: rgba($color-secondary, .05); //var(--ion-color-secondary-tint)

$color-tertiary: #AC003E; //var(--ion-color-tertiary)

// Secundary Colors
$color-warning: #DA8732; //var(--ion-color-warning)
$color-warning-light: #D8C193; //var(--ion-color-warning-tint)

// Greys
$color-grey-lighter: #F8F8F8; //var(--ion-color-medium)
$color-grey-light: #B9B9B9;
$color-grey-medium: #838282;

// Other-shadows
$box-shadow-bottom: 0 2px 32px rgba($color-grey-medium, .28);
$box-shadow-top: 0 -2px 8px rgba($color-grey-medium, .16);

// Form
$color-placeholders: rgba($color-secondary, .4);
$color-backdrop: rgba($color-secondary, .9);

