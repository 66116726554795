.o-label {
  color: $color-secondary;
  font-family: $font-family-sans-serif-bold;
  margin: 0;
  font-size: rem-calc($font-s);
  line-height: 1;
  text-transform: uppercase;
  position: relative;
  display: flex;
  flex-direction: column;

  [class^="icon-"] {
    padding-right: 1rem;
    pointer-events: none;
    position: absolute;
    right: 0;
    bottom: rem-calc(15px);
    z-index: 3;
  }
}