.c-redbutton-logo {
  --padding-start: 0 !important;

  img {
    height: 40px;
    width: auto;
  }

  &--medium {
    height: rem-calc(56px);
    width: rem-calc(59px);
    margin: 0 auto;
  }

  &--large {
    display: flex;

    img {
      height: rem-calc(114px);
      width: rem-calc(78px);
      margin: rem-calc($spacing-xl) auto;
    }
  }
}
