.c-logo {
  display: flex;
  align-items: center;
  align-content: center;
}

.c-logo--background {
  padding: rem-calc(4px);
  justify-content: center;
}

.c-logo--association {
  width: rem-calc(65px);
  max-height: rem-calc(65px);

  img {
    max-width: rem-calc(57px);
    max-height: rem-calc(57px);
  }
}

.c-logo--static {
  width: rem-calc(48px);
}