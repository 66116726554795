.o-select {
  --placeholder-color: #{$color-placeholders};
  --placeholder-font-style: initial;
  --placeholder-font-weight: initial;
  --placeholder-opacity: 1;
  --padding-top: #{rem-calc($spacing-gutter-form)};
  --padding-end: 1rem;
  --padding-bottom: #{rem-calc($spacing-gutter-form)};
  --padding-start: 1rem;
  --background: #{$color-grey-lighter};

  max-width: 100%;
  width: 100%;
  font-size: rem-calc($font-s);
  line-height: 1.35;
  font-family: $font-family-sans-serif;
  border-bottom: 2px solid $color-secondary;
  background-color: $color-grey-lighter;
  text-transform: capitalize;

  &::-ms-expand {
    display: none;
  }
}

.c-select__option {
  width: 100%;
  text-transform: capitalize;
}

ion-select::part(icon) {
  opacity: 0;
}