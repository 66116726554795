.c-form ion-input.input,  //overwrite default ion css
.input {
  --placeholder-color: #{$color-placeholders};
  --placeholder-font-style: initial;
  --placeholder-font-weight: initial;
  --placeholder-opacity: 1;
  --padding-top: #{rem-calc($spacing-gutter-form)};
  --padding-end: 1rem;
  --padding-bottom: #{rem-calc($spacing-gutter-form)};
  --padding-start: 1rem;
  --background: #{$color-white};
  --color: initial;
  font-size: rem-calc($font-s);
  line-height: 1.35;
  font-family: $font-family-sans-serif;

  &--primary {
    --background: #{$color-secondary-lighter};
    border-bottom: 2px solid $color-secondary;
    background-color: $color-secondary-lighter;
  }
}