.c-banner {
  height: rem-calc(96px);
  display: flex;
  --inner-padding-end: 0;

  .c-banner__content {
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    width: 100%;
    height: rem-calc(96px);
  }

  &.c-banner--fifpro {
    --background: var(--ion-color-secondary);
    --padding-end: 0;
    --padding-start: 0;

    .c-banner__content {
      background-image: url($image-path + "brand/brand-fifpro.png");
      background-position: left;
      padding: 0 rem-calc($spacing-gutter);

      img {
        width: rem-calc(198px);
      }
    }
  }

  &.c-banner--red-button {
    --background: #{$color-secondary};
    --padding-end: 0;
    --padding-start: 0;

    .c-banner__content {
      background-image: url($image-path + "red-button-banner.png");
      background-position: right;
    }
  }

  &.c-banner--report {
    --background: var(--ion-color-secondary);
    --padding-end: 0;
    --padding-start: 0;

    .c-banner__content {
      padding: 0 $spacing-gutter;
      background-image: url($image-path + "gray-img-horizontal.png");
      background-position: right;
      justify-content: space-between;
      align-items: center;
    }
  }
}