
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-tabbar {
  margin-bottom: 6px;
}

.icon-player:before {
  content: "\e93b";
}
.icon-warning:before {
  content: "\e906";
}
.icon-webaddress:before {
  content: "\e900";
}
.icon-whistle:before {
  content: "\e901";
}
.icon-wifi:before {
  content: "\e902";
}
.icon-youtube:before {
  content: "\e903";
}
.icon-bad_weather:before {
  content: "\e904";
}
.icon-block:before {
  content: "\e905";
}
.icon-bottomleft:before {
  content: "\e907";
}
.icon-bottomright:before {
  content: "\e908";
}
.icon-brand:before {
  content: "\e909";
}
.icon-calendar:before {
  content: "\e90a";
}
.icon-centre_pitch:before {
  content: "\e90b";
}
.icon-chat:before {
  content: "\e90c";
}
.icon-check:before {
  content: "\e90d";
}
.icon-close:before {
  content: "\e90e";
}
.icon-community:before {
  content: "\e90f";
}
.icon-contact_details:before {
  content: "\e910";
}
.icon-contract:before {
  content: "\e911";
}
.icon-copy:before {
  content: "\e912";
}
.icon-corner:before {
  content: "\e913";
}
.icon-cornerflag:before {
  content: "\e914";
}
.icon-corner--primary:before {
  content: "\e915";
}
.icon-cross--secondary:before {
  content: "\e916";
}
.icon-cup:before {
  content: "\e917";
}
.icon-curve_left:before {
  content: "\e918";
}
.icon-curve_right:before {
  content: "\e919";
}
.icon-dashboard:before {
  content: "\e91a";
}
.icon-dialog:before {
  content: "\e91b";
}
.icon-directions:before {
  content: "\e91c";
}
.icon-divide:before {
  content: "\e91d";
}
.icon-dont-spam:before {
  content: "\e91e";
}
.icon-double-check:before {
  content: "\e91f";
}
.icon-down:before {
  content: "\e920";
}
.icon-download:before {
  content: "\e921";
}
.icon-download_cloud:before {
  content: "\e922";
}
.icon-drag-handle--primary:before {
  content: "\e923";
}
.icon-dropdown:before {
  content: "\e924";
}
.icon-edit:before {
  content: "\e925";
}
.icon-education:before {
  content: "\e926";
}
.icon-email:before {
  content: "\e927";
}
.icon-empty_star:before {
  content: "\e928";
}
.icon-exit_full_screen:before {
  content: "\e929";
}
.icon-facebook:before {
  content: "\e92a";
}
.icon-false:before {
  content: "\e92b";
}
.icon-female:before {
  content: "\e92c";
}
.icon-filter:before {
  content: "\e92d";
}
.icon-filtering:before {
  content: "\e92e";
}
.icon-filtering-active:before {
  content: "\e92f";
}
.icon-folder:before {
  content: "\e930";
}
.icon-folder-open:before {
  content: "\e931";
}
.icon-football:before {
  content: "\e932";
}
.icon-full_screen:before {
  content: "\e933";
}
.icon-full_star:before {
  content: "\e934";
}
.icon-good_weather:before {
  content: "\e935";
}
.icon-grid:before {
  content: "\e936";
}
.icon-growth:before {
  content: "\e937";
}
.icon-half_star:before {
  content: "\e938";
}
.icon-heart:before {
  content: "\e939";
}
.icon-home:before {
  content: "\e93a";
}
.icon-inbox:before {
  content: "\e93c";
}
.icon-info-bordered:before {
  content: "\e93d";
}
.icon-instagram:before {
  content: "\e93e";
}
.icon-language:before {
  content: "\e93f";
}
.icon-lightning:before {
  content: "\e940";
}
.icon-linkedin:before {
  content: "\e941";
}
.icon-lock:before {
  content: "\e942";
}
.icon-login:before {
  content: "\e943";
}
.icon-mail:before {
  content: "\e944";
}
.icon-male:before {
  content: "\e945";
}
.icon-menu-nav:before {
  content: "\e946";
}
.icon-minus:before {
  content: "\e947";
}
.icon-more:before {
  content: "\e948";
}
.icon-multiply:before {
  content: "\e949";
}
.icon-next:before {
  content: "\e94a";
}
.icon-note:before {
  content: "\e94b";
}
.icon-notification:before {
  content: "\e94c";
}
.icon-number:before {
  content: "\e94d";
}
.icon-partners:before {
  content: "\e94e";
}
.icon-percentage:before {
  content: "\e94f";
}
.icon-picto_topleft:before {
  content: "\e950";
}
.icon-picto_topright:before {
  content: "\e951";
}
.icon-pin:before {
  content: "\e952";
}
.icon-plane--primary:before {
  content: "\e953";
}
.icon-plus:before {
  content: "\e954";
}
.icon-postal_adress:before {
  content: "\e955";
}
.icon-quality:before {
  content: "\e956";
}
.icon-refresh:before {
  content: "\e957";
}
.icon-search:before {
  content: "\e958";
}
.icon-send:before {
  content: "\e959";
}
.icon-settings:before {
  content: "\e95a";
}
.icon-share:before {
  content: "\e95b";
}
.icon-sustainabillity:before {
  content: "\e95c";
}
.icon-telephone:before {
  content: "\e95d";
}
.icon-telephone2:before {
  content: "\e95e";
}
.icon-time:before {
  content: "\e95f";
}
.icon-trash:before {
  content: "\e960";
}
.icon-twitter:before {
  content: "\e961";
}
.icon-unlock:before {
  content: "\e962";
}
.icon-pin-remove:before {
  content: "\e963";
}
.icon-up:before {
  content: "\e964";
}
.icon-upload:before {
  content: "\e965";
}
.icon-upload_cloud:before {
  content: "\e966";
}
.icon-video:before {
  content: "\e967";
}
.icon-video-play:before {
  content: "\e968";
}
.icon-video--primary:before {
  content: "\e969";
}
.icon-voice:before {
  content: "\e96a";
}
.icon-add-note:before {
  content: "\e96b";
}
.icon-arrow-back:before {
  content: "\e96c";
}
.icon-arrow-next:before {
  content: "\e96d";
}
.icon-back:before {
  content: "\e96f";
}
