.c-login-container {
  height: 100%;
  display: contents;
}

.c-login {
  background-color: $color-secondary;
  background-image: url($image-path + "gray-img-verticle.png");
  background-size: cover;
  background-position: top left;
  padding: 0 rem-calc($spacing-gutter);
  min-height: 100%;

  h2 {
    font-family: $font-family-sans-serif-bold;
    font-size: rem-calc($font-s);
    color: $color-white;
  }

  form {
    margin-top: rem-calc($spacing-s);
  }

  .btn {
    width: 100%;
    margin-top: rem-calc($spacing-m)
  }

  p {
    color: $color-white;
    margin: rem-calc($spacing-m) 0;
  }

  a {
    color: $color-primary;
    text-decoration: none;
  }
}