.c-toolbar {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  --padding-start: #{rem-calc($spacing-gutter)};
  --padding-end: #{rem-calc($spacing-gutter)};
  --padding-top: #{rem-calc($spacing-s)};
  --padding-bottom:  0;
  --border-width: 0;
}

.header-ios ion-toolbar:last-of-type {
  --border-width: 0;
}