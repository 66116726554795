.ion-page ion-modal {
  .modal-wrapper {
    height: auto;
    min-height: rem-calc(100);
    margin: rem-calc(80) rem-calc($spacing-gutter);
    width: calc(100% - #{rem-calc(30)});
    top: rem-calc(80);
  }

  .ion-page {
    position: relative;
    left: initial;
    right: initial;
    overflow: visible;
    contain: unset;
    top: initial;
    bottom: initial;
  }

  .c-modal__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: rem-calc($spacing-m) rem-calc($spacing-s) 0;

    h1 {
      font-family: $font-family-sans-serif-condensed-bold;
      font-size: rem-calc($font-xl);
      line-height: 1;
      text-transform: uppercase;

      + p {
        margin-top: rem-calc($spacing-s);
        font-size: rem-calc($font-s);
        line-height: 1.5;
      }
    }
  }

  .c-modal__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: rem-calc($spacing-m) rem-calc($spacing-s) rem-calc($spacing-m);
  }

  ion-backdrop {
    --backdrop-opacity: 1;
    background-color: $color-backdrop;
  }
}