h1, h2, h3, h4, h5 {
  color: $color-secondary;
  font-family: $font-family-sans-serif-bold;
  margin: 0;
}

h1 {
  font-size: rem-calc($font-xl);
  line-height: 1;
}

h2 {
  font-size: rem-calc($font-xl);
  line-height: 1;
  font-family: $font-family-sans-serif-condensed-bold;
  text-transform: uppercase;
}

h3 {
  font-size: rem-calc($font-s);
  line-height: 1;
  text-transform: uppercase;
}

p {
  font-family: $font-family-sans-serif;
  color: $color-secondary;
  font-size: rem-calc($font-s);
  line-height: 1.5;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

h2 + p {
  margin-block-start: rem-calc($spacing-xs);
}

p + p {
  margin-block-start: rem-calc($spacing-m);
}

span {
  color: $color-secondary;
}