.c-header {
  background: $color-white;
  border-bottom: 1px solid;
  border-color: $color-secondary-lighter;
  height: rem-calc(65px);
  margin: 0;

  .c-header__logo,
  .c-header__title,
  .c-header__cta {
    --background: transparent;
    display: flex;
    align-items: center;
  }

  .c-header__logo {
    --padding-start: 0;
    width: 30px;
    height: 30px;
    justify-content: flex-start;

    img {
      width: 30px;
      height: 30px;
      object-fit: cover;
    }
  }

  .c-header__title {
    font-family: $font-family-sans-serif-bold;
    letter-spacing: normal;
    color: $color-secondary;
    text-align: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 1rem;
  }

  .c-header__cta {
    --padding-end: 0;
    --padding-start: 0;
    padding-right: 0;
    padding-left: 0;
    width: rem-calc(46px);
    justify-content: flex-end;
    color: $color-tertiary;

    ion-button {
      --padding-end: 0;
      --padding-start: 0;
      --color: #{$color-tertiary};
      padding-right: 0;
      padding-left: 0;
      margin-left: 0;
      margin-right: 0;
    }

    [class^="icon-"] {
      color: $color-primary;
      font-size: rem-calc($font-m);
    }
  }
}