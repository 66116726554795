ion-button {
  &:hover {
    outline: none;
  }
}

.btn {
  --padding-top: #{rem-calc($spacing-gutter-form)};
  --padding-end: 1rem;
  --padding-bottom: #{rem-calc($spacing-gutter-form)};
  --padding-start: 1rem;
  --border-radius: 0;

  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none;
  font-family: $font-family-sans-serif-condensed-bold;
  letter-spacing: initial;
  font-weight: normal;
  margin: 0;
  padding: 0;
  font-size: rem-calc($font-ml);
  line-height: 1;
  height: rem-calc(48px);
  text-transform: uppercase;
}

.btn--upload {
  border: 1px dashed $color-secondary;
  padding: 0 rem-calc(16);
  width: 100%;
}

.btn--hollow {
  background: $color-white;
  color: $color-primary;
}

.btn--solid {
  color: $color-white;

  span {
    color: $color-white;
  }

  [class^="icon-"] {
    margin-left: auto;
    color: $color-white;
  }
}
