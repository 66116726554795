/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */
/** Ionic CSS Variables **/
  /** primary **/
:root {
  --ion-background-color: #fff;
  --ion-font-family: "FS Industrie", sans-serif, Arial, Helvetica;
  --padding-bottom: 0;
  --padding-bottom: 0;

  --color: #221C46;

  --ion-color-primary: #4CBF8C;
  --ion-color-primary-rgb: 76,191,140;
  --ion-color-primary-contrast: #3fb27f;
  --ion-color-primary-contrast-rgb: 0,0,0;
  --ion-color-primary-shade: #43a87b;
  --ion-color-primary-tint: #5ec598;

  --ion-color-secondary: #221C46;
  --ion-color-secondary-rgb: 34,28,70;
  --ion-color-secondary-contrast: #150f39;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #1e193e;
  --ion-color-secondary-tint: #E8E8EC;

  --ion-color-tertiary: #AC003E;
  --ion-color-tertiary-rgb: 172,0,62;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #970037;
  --ion-color-tertiary-tint: #b41a51;

  --ion-color-success: #00C288;
  --ion-color-success-rgb: 0,194,136;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #00ab78;
  --ion-color-success-tint: #1ac894;

  --ion-color-warning: #DA8732;
  --ion-color-warning-rgb: 232,130,7;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #cc7206;
  --ion-color-warning-tint: #D8C193;

  --ion-color-danger: #BC003C;
  --ion-color-danger-rgb: 188,0,60;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #a50035;
  --ion-color-danger-tint: #c31a50;

  --ion-color-dark: #231C49;
  --ion-color-dark-rgb: 35,28,73;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1f1940;
  --ion-color-dark-tint: #39335b;

  --ion-color-medium: #f8f8f8;
  --ion-color-medium-rgb: 244,243,246;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #d7d6d8;
  --ion-color-medium-tint: #f5f4f7;

  --ion-color-light: #908DA2;
  --ion-color-light-rgb: 255,255,255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #e0e0e0;
  --ion-color-light-tint: #ffffff;

}