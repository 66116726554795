@font-face {
  font-family: 'FS Industrie';
  src: url("#{$font-path}FSIndustrie_Standard/Regular/FSIndustrie-Regular.woff");
  src: url("#{$font-path}FSIndustrie_Standard/Regular/FSIndustrie-Regular.woff") format('woff'), url("#{$font-path}FSIndustrie_Standard/Regular/FSIndustrie-Regular.woff2") format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'FS Industrie Bold';
  src: url("#{$font-path}FSIndustrie_Standard/Bold/FSIndustrie-Bold.woff");
  src: url("#{$font-path}FSIndustrie_Standard/Bold/FSIndustrie-Bold.woff") format('woff'), url("#{$font-path}FSIndustrie_Standard/Bold/FSIndustrie-Bold.woff2") format('woff2');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'FS Industrie Cd Bold';
  src: url("#{$font-path}FSIndustrie_Condensed/Bold/FSIndustrieCd-Bold.woff");
  src: url("#{$font-path}FSIndustrie_Condensed/Bold/FSIndustrieCd-Bold.woff") format('woff'), url("#{$font-path}FSIndustrie_Condensed/Bold/FSIndustrieCd-Bold.woff2") format('woff2');
  font-weight: bold;
  font-style: normal;
}

@include font-face(icomoon, "#{$font-path}icomoon/icomoon", normal, normal);

$font-family-sans-serif: "FS Industrie", sans-serif, Impact, Helvetica, Arial;
$font-family-sans-serif-condensed-bold:   "FS Industrie Cd Bold", sans-serif, Impact, Helvetica, Arial;
$font-family-sans-serif-bold:   "FS Industrie Bold", sans-serif, Impact, Helvetica, Arial;

$font-xxs: 8px;
$font-xs: 14px;
$font-s: 18px;
$font-m: 20px;
$font-ml: 24px;
$font-l: 26px;
$font-xl: 32px;